import styled from '@emotion/styled';
import { DarkThemeProps, ClickableThemeProps } from '@theme/base';

interface NavLabelProps extends DarkThemeProps, ClickableThemeProps {
  noIcon?: boolean;
}
export const NavLabel: any = styled.span(({ theme, isClickable, isDark, noIcon }: NavLabelProps) => ({
  ['&, a, a:visited']: {
    padding: 10,
    whiteSpace: 'nowrap',
    paddingLeft: noIcon ? 10 : theme.custom.spacing.large,
    paddingRight: 0,
    cursor: isClickable ? 'pointer' : 'default',
    ...(theme.custom.typography.paragraph as any),
    fontWeight: 500,
    lineHeight: '2.7rem',
    color: isDark ? theme.custom.colors.white : theme.custom.colors.black,
    textDecoration: 'none',
    ['&:hover']: {
      color: theme.custom.colors.group1.base
    }
  }
}));
