import Link from 'next/link';
import React from 'react';
import { Page } from '@model/common/pages';
import { OfferType } from '@model/offers/offers';
import { CMS_ONLY_OFFER_TYPES, CUSTOM_PAGE_OFFER_TYPES } from '@model/offers/offers';
import { PlainLinkStyles } from '@styles/common';

export interface HelpLinkProps {
  path: string;
  children?: any;
}

export const OfferLink = ({ path, children }: HelpLinkProps) => {
  const offerType = path.substring(path.lastIndexOf('/') + 1);
  const isCMSOnlyOffer =
    CMS_ONLY_OFFER_TYPES.includes(offerType as OfferType) || CUSTOM_PAGE_OFFER_TYPES.includes(offerType as OfferType);
  return (
    <Link
      prefetch={false}
      href={isCMSOnlyOffer ? path : `${Page.OFFERS}/[offerType]`}
      as={path}
      passHref
      style={PlainLinkStyles}
    >
      {children}
    </Link>
  );
};
