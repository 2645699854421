import React, { useEffect } from 'react';
import { Global } from '@emotion/react';
import { MuiTheme } from '@theme/mercuryHolidaysTheme';
import StylesProvider from '@mui/styles/StylesProvider';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { globalStyles } from '@theme/base';
import { isServer } from '@util/is-server';
import { GtmBootstrap } from '@model/bootstrap/gtm-bootstrap';
import { PolyfillBootstrap } from '@model/bootstrap/polyfill-bootstrap';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { getCMSAppVariantId } from '@state/cms';
import { getAbta } from '@state/agent/agentSelectors';
import { RouterEventsBootstrap } from '@model/bootstrap/router-events-bootstrap';
import { WithT } from '@components/hoc';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import { PersistGate } from 'redux-persist/integration/react';
import { useRouter } from 'next/router';
import { initializePath, getPath } from '@state/app';
import { PageWrapper } from '@layout/PageWrapper';
import { CookiesBanner } from '@components/common/cookies-banner/CookiesBanner';
import { DevBanner } from '@components/dev-banner/DevBanner';
import { RootModalContainer } from '@components/modal/root/RootModalContainer';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { EnvConfig } from '@model/config/env-config';
import { WithAuth } from '@model/bootstrap/with-auth';
import { getBookingPageFromUrl } from '@model/common/pages/get-booking-page-from-url';
import { PaywallBannerModalContainer } from '@components/modal/promo-banner';
import Cookies, { Cookie } from '@model/common/cookie/cookie';
import { Uuid } from '@model/bootstrap/uuid-bootstrap';
import { Sfsk } from '@model/bootstrap/sfsk-bootstrap';
import locales from '../../public/assets/locales/locales.json';
import { XdebugBootstrap } from '@model/bootstrap/xdebug-bootstrap';
import { PromoBannerContainer } from '@components/common/banner/promo/PromoBannerContainer';
import { getPromoBannerData } from '@state/promo-banner/promoBannerSelectors';

export const withGlobalProvider = (Page: any) => {
  return (ownProps: any) => {
    const dispatch = useDispatch();
    const store: any = useStore();
    const persistor = store.__persistor;
    const path = useSelector(getPath);
    const abta = useSelector(getAbta);
    const router = useRouter();
    const routerPath = router.asPath;
    const gtmBootstrap = new GtmBootstrap();

    useEffect(() => {
      if (!path) {
        new XdebugBootstrap().initialize();
      }
      if (isServer) {
        return;
      }
      if (!path) {
        // one time setup
        gtmBootstrap.initialize();
        new Sfsk().initialize();
        momentDurationFormatSetup(moment as any);
        new PolyfillBootstrap().initialize();
        new WithAuth().initialize();
        new RouterEventsBootstrap().initialize({
          dispatch
        });
        new Uuid().initialize();
      }
      if (path !== routerPath) {
        // on path change
        dispatch(initializePath(routerPath));
        setTimeout(() => {
          gtmBootstrap.performPageView(abta);
        }, 0);
      }
    }, [routerPath]);

    const gtmCookiesAccepted = new Cookies().get(Cookie.COOKIES_ACCEPTED);
    const cookiesAccepted = gtmCookiesAccepted === '1' ? true : gtmCookiesAccepted === '0' ? false : null;

    const appVariantId = useSelector(getCMSAppVariantId);
    const promoBannerData = useSelector(getPromoBannerData);

    const localisations = { ...locales.labels, ...locales[appVariantId] };

    return (
      <StylesProvider>
        <Global styles={globalStyles(MuiTheme)} />
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={MuiTheme}>
            <WithT localeLabels={localisations}>
              <GoogleReCaptchaProvider reCaptchaKey={EnvConfig.get().GOOGLE_RE_CAPTCHA}>
                <PersistGate persistor={persistor}>
                  {() => (
                    <>
                      <RootModalContainer />
                      <DevBanner />
                      <PromoBannerContainer
                        path={routerPath}
                        promoBanners={promoBannerData}
                        tripType={ownProps.promoBannerTripType}
                      />
                      <PageWrapper {...ownProps} Page={Page} />
                      <CookiesBanner cookiesAccepted={cookiesAccepted} isBookingPage={!!getBookingPageFromUrl()} />
                      <PaywallBannerModalContainer />
                    </>
                  )}
                </PersistGate>
              </GoogleReCaptchaProvider>
            </WithT>
          </ThemeProvider>
        </StyledEngineProvider>
      </StylesProvider>
    );
  };
};
