import { Formik, FormikProps, FormikValues } from 'formik';
import styled from '@emotion/styled';
import { ThemeProps } from '@theme/base';
import { mq } from '@styles/breakpoints';
import { mixins } from '@styles/mixins';
import { ModalContainer } from '@styles/modal';
import { Page } from '@model/common';
import { TEXT_FIELD_MAX_LENGTH } from '@model/forms';
import { validateEmailSignUpForm } from '@model/forms/validation/email-sign-up-form-validation';
import { useI18NextContext } from '@components/hooks';
import { Button, ButtonColor, ButtonSize, ButtonVariant } from '@components/material-ui';
import { EmailForm, FormContainer } from '@components/newsletter-signup/NewsletterSignUpForm';
import { getErrors } from '@util/forms';
import { BLANK_WINDOW } from '@util/blank-window';
import Cookies, { Cookie } from '@model/common/cookie/cookie';
import moment from 'moment';

/* ***************** *
 *       Types       *
 * ***************** */
export interface PaywallBannerModalProps {
  onSubscribe: (email: string) => void;
  onDismiss: () => void;
  amount: string;
}

/* ****************** *
 *       Styles       *
 * ****************** */
const Container: any = styled.div(() => ({
  alignSelf: 'flex-start',
  boxSizing: 'content-box',
  display: 'grid',
  gridTemplateColumns: '1fr',
  padding: 0,
  boxShadow: '0px 75px 150px 0px rgba(0, 0, 0, 0.75)',
  [mq.small]: {
    padding: 0
  },
  [mq.medium]: {
    alignSelf: 'center',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr',
    maxWidth: 1000
  }
}));

const ModalImg: any = styled.div(({ theme }: ThemeProps) => ({
  backgroundColor: theme.custom.colors.white,
  overflow: 'hidden'
}));

const SaleImage = styled.img({
  height: 'auto',
  width: '100%'
});

const ModalContent: any = styled.div(({ theme }: ThemeProps) => ({
  padding: theme.custom.spacing.large,
  marginBottom: theme.custom.spacing.xLarge,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  backgroundColor: theme.custom.colors.white,

  [mq.medium]: {
    marginBottom: 0,
    padding: theme.custom.spacing.large * 2
  }
}));

const Heading: any = styled.h4(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.h4 as any),
  paddingBottom: theme.custom.spacing.medium,
  paddingTop: theme.custom.spacing.medium,
  margin: 0,

  [mq.small]: {
    paddingBottom: theme.custom.spacing.large,
    paddingTop: theme.custom.spacing.large
  }
}));

const SubHeading: any = styled.h5(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.h5 as any),
  paddingBottom: theme.custom.spacing.medium,
  margin: 0,
  [mq.small]: {
    paddingBottom: theme.custom.spacing.large
  }
}));

const EmailField = styled(EmailForm)(({ theme }: ThemeProps) => ({
  marginBottom: theme.custom.spacing.medium
}));

const SubmitButton = styled(Button)(({ theme }: ThemeProps) => ({
  marginBottom: theme.custom.spacing.medium
}));

export const Link = styled.a(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  ...mixins(theme).link
}));

const Footer: any = styled.div({});

export const PaywallBannerModal = ({ onSubscribe, onDismiss, amount }: PaywallBannerModalProps) => {
  const t: any = useI18NextContext();

  const onSubmit = ({ email }: any) => {
    if (email) {
      onSubscribe(email);
    }
  };

  const closeModal = () => {
    onDismiss();
    new Cookies().set({
      id: Cookie.CANCEL_PROMO,
      value: 'true',
      options: {
        path: '/',
        expires: moment().add(2, 'years').toDate(),
        sameSite: 'strict',
        secure: true
      }
    });
  };

  const handleValidate = (values: any) => {
    const formValidation = validateEmailSignUpForm(values);
    return formValidation.errors;
  };

  return (
    <ModalContainer>
      <Container>
        <ModalImg>
          <SaleImage src={'/assets/images/promo/sign-up-and-save.jpg'} />
        </ModalImg>
        <ModalContent>
          <Heading>{t('paywall-banner__modal-header')}</Heading>
          <SubHeading>{t('paywall-banner__modal-cta')}</SubHeading>
          <div>
            <Formik
              initialValues={{ email: '' }}
              onSubmit={onSubmit}
              validate={handleValidate}
              validateOnBlur
              validateOnChange
              isInitialValid={false}
              enableReinitialize
            >
              {({ handleChange, handleBlur, values, errors, touched, handleSubmit }: FormikProps<FormikValues>) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <FormContainer>
                      <EmailField
                        type={'text'}
                        name={'email'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        maxLength={TEXT_FIELD_MAX_LENGTH}
                        placeholder={t('email-signup__email-placeholder')}
                        error={getErrors(touched, errors, 'email')}
                      />
                      <SubmitButton
                        variant={ButtonVariant.OUTLINED}
                        color={ButtonColor.SECONDARY}
                        label={t('paywall-banner__modal-button-label')}
                        size={ButtonSize.SMALL}
                        type={'submit'}
                      />
                    </FormContainer>
                  </form>
                );
              }}
            </Formik>
          </div>
          <Footer>
            <p>
              {t('paywall-banner__modal-legend', { amount })}
              <Link href={`${Page.HELP}${Page.PRIVACY_POLICY}`} target={BLANK_WINDOW} rel="noopener">
                {t('common__label--privacy-policy')}
              </Link>
            </p>
            <p>
              {t('paywall-banner__modal-cancel', { amount })}
              <Link onClick={closeModal} rel="noopener">
                {t('paywall-banner__modal-continue-browsing')}
              </Link>
            </p>
          </Footer>
        </ModalContent>
      </Container>
    </ModalContainer>
  );
};
