import { Icon } from '../../generic-icon';
import { OpeningTimesContainer } from '../../opening-times/OpeningTimesContainer';
import React, { useState } from 'react';
import Markdown from 'react-markdown';
import styled from '@emotion/styled';
import { Theme, ThemeProps, DarkThemeProps } from '@theme/base';
import { NavLabel } from '../NavLabel';
import { DesktopNavItem } from '../DesktopNavItem';
import { withTheme } from '@emotion/react';
import { LinkComponent } from '@components/common/description/TextMarkdown';

export interface PhoneNumberProps {
  isDark: boolean;
  telephoneNumber: string;
  theme?: Theme;
}

const phoneNumberIconStyles: (theme: Theme, isDark: boolean) => any = (theme: Theme, isDark: boolean) => ({
  color: isDark ? theme.custom.colors.white : theme.custom.colors.black,
  fontSize: '1.5rem',
  paddingRight: theme.custom.spacing.xSmall
});

const Container: any = styled(DesktopNavItem)(({ theme, isDark }: DarkThemeProps) => ({
  color: isDark ? theme.custom.colors.white : theme.custom.colors.black,
  ['> span']: {
    ...(theme.custom.typography.h5 as any),
    paddingLeft: theme.custom.spacing.xLarge,
    paddingTop: 10
  },
  ['&:hover i:first-of-type, &:hover > span:first-of-type']: {
    color: `${isDark ? theme.custom.colors.group9.base : theme.custom.colors.black} !important`
  }
}));

const PhoneNumberLabel = styled(NavLabel)({
  position: 'relative'
});

const Number = styled.div(({ theme }: ThemeProps) => ({
  display: 'inline-block',
  span: {
    padding: 0
  },
  ['p']: {
    ...(theme.custom.typography.h5 as any),
    margin: 0
  }
}));

export const PhoneNumberComponent = ({ isDark, theme, telephoneNumber }: PhoneNumberProps) => {
  const [showOpeningTimes, setShowOpeningTimes] = useState<boolean>(false);

  return (
    <Container
      isDark={isDark}
      onMouseEnter={() => setShowOpeningTimes(true)}
      onMouseLeave={() => setShowOpeningTimes(false)}
    >
      <PhoneNumberLabel isClickable isDark={isDark}>
        <Icon name={'mhi-telephone'} css={phoneNumberIconStyles(theme!, isDark)} />
        <Number>
          <Markdown children={telephoneNumber} components={LinkComponent} />
        </Number>
        <OpeningTimesContainer show={showOpeningTimes} />
      </PhoneNumberLabel>
    </Container>
  );
};

PhoneNumberComponent.defaultProps = {
  isDark: true
};

export const PhoneNumber = withTheme(PhoneNumberComponent);
