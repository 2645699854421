import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useI18NextContext } from '@components/hooks';
import { BaseModal } from '@components/common/modal';
import { mq } from '@styles/breakpoints';
import { ModalContainer, Container as BaseContainer, Paragraph } from '@styles/modal';
import { Button, ButtonColor, ButtonSize } from '@components/material-ui';
import { ThemeProps } from '@theme/base';

export interface FormErrorProps {
  title: string;
  body: string;
  buttonLabel: string;
  onClick: () => void;
  renderErrors: () => void;
}

const Heading: any = styled.div(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.h5 as any),
  marginBottom: theme.custom.spacing.large
}));

const Container: any = styled(BaseContainer)(({ theme }: ThemeProps) => ({
  [mq.small]: {
    width: 580,
    padding: theme.custom.spacing.xLarge
  }
}));

const ScrollContainer: any = styled.div(({ theme }: ThemeProps) => ({
  height: '100%',
  overflow: 'scroll',
  maxHeight: 300,
  marginBottom: theme.custom.spacing.large
}));

export const FormError: FC<FormErrorProps> = ({ onClick, title, body, renderErrors, buttonLabel }: FormErrorProps) => {
  const t: any = useI18NextContext();
  return (
    <BaseModal open={true} noBackdropDismiss hideCloseButton>
      <ModalContainer>
        <Container>
          <Heading>{t(title)}</Heading>
          <Paragraph>{t(body)}</Paragraph>
          <ScrollContainer>{renderErrors()}</ScrollContainer>
          <Button label={buttonLabel} size={ButtonSize.SMALL} color={ButtonColor.SECONDARY} onClick={onClick} />
        </Container>
      </ModalContainer>
    </BaseModal>
  );
};
